import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SenderComponent } from './sections/sender/sender.component';
import { ComingoutComponent } from './sections/comingout/comingout.component';
import { FaqComponent } from './sections/faq/faq.component';
import { SupportingComponent } from './sections/supporting/supporting.component';
import { YesComponent } from './sections/yes/yes.component';
import { NoComponent } from './sections/no/no.component';
import { SentComponent } from './sections/sent/sent.component';


const routes: Routes = [
  { path: '', redirectTo: '/sender', pathMatch: 'full' },
  { path: 'sender', component: SenderComponent },
  { path: 'comingout', component: ComingoutComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'supporting', component: SupportingComponent },
  { path: 'yes', component: YesComponent },
  { path: 'no', component: NoComponent },
  { path: 'sent', component: SentComponent },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
