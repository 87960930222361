import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { MatButtonModule, MatInputModule, MatToolbarModule, MatListModule, MatSidenavModule, MatTableModule, MatIconModule, MatProgressSpinnerModule, MatSortModule, MatPaginatorModule, MatCheckboxModule, MatTooltipModule, MatSlideToggleModule, MatDatepickerModule, MatNativeDateModule, MatTabsModule, MatGridListModule, MatSelectModule, MatSliderModule, MatRadioButton, MatRadioModule, MatCardModule } from '@angular/material';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SenderComponent } from './sections/sender/sender.component';
import { ComingoutComponent } from './sections/comingout/comingout.component';
import { SupportingComponent } from './sections/supporting/supporting.component';
import { FaqComponent } from './sections/faq/faq.component';
import { NavComponent } from './nav/nav.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { YesComponent } from './sections/yes/yes.component';
import { NoComponent } from './sections/no/no.component';
import { SentComponent } from './sections/sent/sent.component';
import { EnvironmentService } from 'src/environments/environment.service';

export function init_app(appLoadService: EnvironmentService) {
  return () => appLoadService.init();
}

@NgModule({
  declarations: [
    AppComponent,
    SenderComponent,
    ComingoutComponent,
    SupportingComponent,
    FaqComponent,
    NavComponent,
    YesComponent,
    NoComponent,
    SentComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatInputModule,
    MatRadioModule,
    MatToolbarModule,
    MatListModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatCardModule,
    MatSortModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatSidenavModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTabsModule,
    MatSliderModule,
    MatGridListModule,
    MatSelectModule,
    HttpClientModule,
  ],
  providers: [
    EnvironmentService,
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [EnvironmentService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
