import { Component, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  public menuVisible: boolean = true;

  public ngAfterViewInit(): void {
    if (window.innerWidth < 768) {
      this.menuVisible = false;
    }
  }

  public toggleMenu(): void {
    this.menuVisible = !this.menuVisible;
  }
}
