import { Injectable, enableProdMode } from '@angular/core';
import { environment } from './environment';
import { HttpClient } from '@angular/common/http';
import { from, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class EnvironmentService {
    private env: any;

    constructor(protected http: HttpClient) { }

    public init(): Promise<any> {
        this.env = null;
        return from(this.http.get<any>('/assets/env.json').pipe(
            catchError(err => {
                console.log('no environment file found, using defaults');
                this.env = environment;
                return of(environment);
            }),
            map(e => {
                console.log('found environment file', e);
                this.env = e;
                return e;
            }))
        ).toPromise();
    }

    public getBaseURL(): string {
        return this.env.baseURL;
    }
}
