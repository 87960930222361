import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { EnvironmentService } from 'src/environments/environment.service';

@Component({
  selector: 'app-sender',
  templateUrl: './sender.component.html',
  styleUrls: ['./sender.component.scss']
})
export class SenderComponent implements OnInit {
  public to_email: string;
  public my_email: string;
  public template: string;
  public loading = new BehaviorSubject<boolean>(false);
  public previewBaseUri:string = '';

  constructor(protected envService: EnvironmentService, private http: HttpClient, private router: Router, ) {
    console.log(envService.getBaseURL());
    this.previewBaseUri = envService.getBaseURL();
  }

  ngOnInit() {
  }

  send() {
    const me = this;
    if (this.to_email === this.my_email) {
      alert('Unfortunately, this tool cannot be used to come out to yourself.');
      return;
    }
    this.loading.next(true);
    this.http.post(this.envService.getBaseURL(),
      { cmd: 'send', to_email: this.to_email, my_email: this.my_email, template: this.template },
      { observe: 'response' }).subscribe(
        (success) => {
          console.log(success);
          if (success.status === 429) {
            this.loading.next(false);
            alert('System is overloaded. Please try again later.');
          } else {
            setTimeout(() => {
              me.router.navigateByUrl('/sent');
            }, 1000);
          }
        },
        (error) => {
          console.log(error);
          this.loading.next(false);
          if (error.status === 429) {
            alert('System is overloaded. Please try again later.');
          } else {
            alert('An unknown error occurred. Your message was not sent.');
          }
        }
      );
  }
}
